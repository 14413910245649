<template>
  <div>
    <div class="text-center my-3">
    RESULT
    <!-- <div>
        <div class="result-heading flex p-2 justify-between" v-on:click="toggle()">
            <h4 class="result-name">Result</h4>
            <button class="btn btn-transparent">
                <caret-down-icon v-if="!isCollapsed" v-bind:alt="'Open '" v-bind:title="'Open '"></caret-down-icon>
                <caret-up-icon v-else v-bind:alt="'Close '" v-bind:title="'Close '"></caret-up-icon>
            </button>
        </div>
        <div class="relative text-left" v-if="isCollapsed">
            <div class=" my-2">
            <h6 v-for="(item,index) of isSpoofed" :key="index"><span>{{index+1}} - </span> Face Spoofed : {{item ? 'Spoofed' : 'Not Spoofed'}}</h6>
            </div>
        </div>
    </div> -->

    <result-drop-down-section :title="'Request'"
    v-bind:receivedResult="requestMade"/>

    <result-drop-down-section :title="'Response JSON'"
    v-bind:receivedResult="result"/>
    
    </div>
</div>
</template>

<script>
import ResultDropDownSection from '../ResultDropDownSection.vue';
// import CaretDownIcon from '../../icons/CaretDownIcon';
// import CaretUpIcon from '../../icons/CaretUpIcon.vue';

export default {
  components: { 
    ResultDropDownSection,
    // CaretDownIcon,
    // CaretUpIcon
    },
    data() {
      return {
        isCollapsed: Boolean

      }
    },
    methods: {
      toggle() {
          this.isCollapsed = !this.isCollapsed;
        }
    },
    

    props: {
      result:Array,
      isLoading:Boolean,
      requestMade: Array,
      isSpoofed: Array
    }

}
</script>

<style>

</style>